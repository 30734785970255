import { render, staticRenderFns } from "./registerV3.vue?vue&type=template&id=779c0738&scoped=true"
import script from "./registerV3.vue?vue&type=script&lang=js"
export * from "./registerV3.vue?vue&type=script&lang=js"
import style0 from "./registerV3.vue?vue&type=style&index=0&id=779c0738&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779c0738",
  null
  
)

export default component.exports